import { ConferenceAd } from "../types/conference-ad";
import * as React from "react";

function DetailPageConferenceAd(props: { conferenceAd: ConferenceAd }) {
  const { conferenceAd } = props;
  return (
    <div className="my-8 sm:max-w-prose mx-auto">
      <a
        href={conferenceAd.link}
        target="_blank"
        className="block group relative overflow-hidden rounded-xl shadow-lg transition-all duration-300 hover:shadow-2xl hover:scale-[1.02]"
      >
        <span className="absolute top-2 right-2 text-sm font-light bg-blue-900 rounded-md px-1 py-0 text-white z-10">
          NEXT EDITION
        </span>
        <div className="relative">
          <img
            className="w-full object-cover"
            src={`/conference-ads/${conferenceAd.image}`}
            alt={conferenceAd.title}
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent opacity-100 group-hover:opacity-0 transition-opacity duration-300" />
        </div>
      </a>
    </div>
  );
}

export default DetailPageConferenceAd;
