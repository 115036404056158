import React from "react";

type SponsorType = {
  name: string;
  logo: string;
  weight: number;
  link: string;
  pitch: string;
  edition: string | null;
  conference: string | null;
};

interface SponsorAdTileProps {
  sponsor: SponsorType;
}

export default function SponsorAdTile({ sponsor }: SponsorAdTileProps) {
  return (
    <div className="sm:max-w-prose mx-auto mt-4 lg:mt-8">
      <a href={sponsor.link}>
        <div className="border-blue-900 px-4 py-2 rounded-md border-2 relative hover:bg-blue-900 duration-500 w-full">
          <span className="absolute top-2 right-2 text-sm font-light bg-blue-900 rounded-md px-1 py-0 text-white">
            SPONSOR
          </span>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="basis-1/4 sm:self-center grow p-1">
              <img
                className="w-1/2 sm:w-full max-w-md sm:max-w-full mx-auto"
                src={`/sponsors/${sponsor.logo}`}
                alt={`${sponsor.name} logo`}
              />
            </div>
            <div className="basis-3/4 grow">
              <div className="text-xl font-bold">{sponsor.name}</div>
              <div>{sponsor.pitch}</div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
