import conferenceAds from "../../private-data/conference-ads/conference-ads.json";
import { ConferenceAd } from "../types/conference-ad";

const findConferenceAdBySlug = (slug: string): ConferenceAd | null => {
  if (conferenceAds.length === 0) {
    return null;
  }
  return (
    conferenceAds.find((conferenceAd) => conferenceAd.slug === slug) || null
  );
};

export const conferenceAdForSlug = (
  conferenceSlug: string
): ConferenceAd | null => {
  switch (conferenceSlug) {
    case "frenchkit":
    case "cocoaheads-paris":
    case "swift-connection":
      return findConferenceAdBySlug("swift-connection-2024");
    case "react-native-connection":
    case "react-native-paris":
      return findConferenceAdBySlug("react-native-connection-2024");
    case "flutter-connection":
      return findConferenceAdBySlug("flutter-connection-2024");

    default:
      return null;
  }
};
